import { API, gaTrackEvent, sessionStorage } from '@geturgently/ui-util';
import { GoogleMapUly, LocationType } from '@geturgently/uly-component';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Box, Link, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import NotFoundPage from 'components/shared/NotFoundPage';
import analytics from 'constants/analytics';
import dataTestIds from 'constants/dataTestIds';
import routes from 'constants/routes';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { noop } from 'utils/commonUtility';
import CONFIG from 'utils/config';
import { getLanguage, getTranslations } from 'utils/languages';
import {
  CurrentLocationBox,
  CurrentLocationButton,
  Footer,
  FooterTexts,
  FooterTitle,
  LocationInfo,
  MapBox,
  NotificationBox,
} from './Map.styles';
import Notification from './Notification';

export interface IMapProps {
  footerTitle?: string;
  headerLabel?: string;
  notificationMessage?: string;
  locationAdditionalInfo?: string;
  showCurrentLocation?: boolean;
  onCurrentLocation?: () => void;
  customMarkerIcon?: string;
  onConfirm?: (location: LocationType) => void;
  searchNotificationMessage?: ReactNode;
}

interface INotificationState {
  styles?: Record<string, string>;
  title: string;
  description: string;
}

const GoogleMap: FC<IMapProps> = ({
  onConfirm = noop,
  footerTitle = '',
  headerLabel = '',
  customMarkerIcon,
  locationAdditionalInfo,
  notificationMessage,
  onCurrentLocation = noop,
  searchNotificationMessage,
  showCurrentLocation = false,
}) => {
  const [searchParams] = useSearchParams();
  const phone = searchParams.get('phone');
  const referenceId = searchParams.get('referenceId');
  const partnerName = searchParams.get('partnerName');
  const langCode = searchParams.get('langCode');
  const translations = getTranslations(langCode as string);
  const navigate = useNavigate();
  const [pageSizeState, setPageSizeState] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [config, setConfig] = useState();

  const defaultNotificationText = translations.MOVE_THE_MAP_OR_ADJUST;
  notificationMessage = notificationMessage || defaultNotificationText;

  const configURL = process.env.configURL || '';
  const commonHeaders = useMemo(() => {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      apiKey: process.env.ACCESS_TOKEN,
    };
  }, []);

  const [location, setLocation] = useState<LocationType>({
    address: '37 Railroad Ave, Long Island City, NY 11101, USA',
    latitude: 40.73064252063256,
    longitude: -73.93537074603272,
  });
  const [showNotification, setShowNotification] = useState(true);
  const [notificationState, setNotificationState] =
    useState<INotificationState>();

  const [showLocationNotification, setShowLocationNotification] =
    useState(false);

  // Effects
  useEffect(() => {
    window.addEventListener('resize', () => {
      setPageSizeState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
    return () => {
      window.removeEventListener('resize', () => {
        setPageSizeState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
    };
  }, []);

  // Commenting this useEffect implementation since it's still not clear where we will use
  // This Config data which we are fetching from the API endpoint
  useEffect(() => {
    API(
      configURL + '&device=desktop&os=Mac&app=location-detection&industry=us',
      {
        method: 'GET',
        customHeaders: { ...commonHeaders },
      },
    ).then((res) => {
      setConfig(res.data.data[0].config);
    });
  }, [setConfig, configURL, commonHeaders]);

  useEffect(() => {
    if (partnerName) sessionStorage.setItem('partnerName', partnerName);
    if (langCode) sessionStorage.setItem('langCode', langCode);
  }, [partnerName, langCode]);

  useEffect(() => {
    window.navigator.geolocation?.getCurrentPosition(
      (location) => {
        setLocation({
          longitude: location.coords.longitude,
          latitude: location.coords.latitude,
        });
      },
      () => setShowLocationNotification(true),
    );
  }, []);

  // Functions
  const sendLocation = async (): Promise<void> => {
    try {
      await API(
        process.env.locationPOST +
          '/messages/detected-location' +
          '?phone=' +
          phone +
          '&latitude=' +
          location?.latitude +
          '&longitude=' +
          location?.longitude +
          '&fullAddress=' +
          location?.address,
        {
          method: 'POST',
          customHeaders: { ...commonHeaders },
          payload: {
            referenceId,
            location,
          },
        },
      );
      navigate(routes.THANK_YOU);
    } catch (error) {
      setShowNotification(true);
      setNotificationState({
        description: (error as { message: string }).message,
        title: 'Server Error',
        styles: { color: 'white', bgcolor: 'red' },
      });
    }
  };

  const onSearch = (nextLocation: LocationType): void => {
    if (!nextLocation?.latitude || !nextLocation?.longitude) return;
    setLocation(nextLocation);
  };

  const onNotificationClose = (): void => {
    setShowNotification(false);
  };

  const onLocationNotificationClose = (): void => {
    setShowLocationNotification(false);
  };

  return phone ? (
    <MapBox pageSizeState={pageSizeState} data-testid="map-component">
      <GoogleMapUly
        zoom={15}
        locations={[location]}
        language={getLanguage()}
        onSearch={onSearch}
        headerLabel={partnerName || headerLabel}
        apiKey={CONFIG.GOOGLE_MAP_KEY}
        markerProps={{ centerAlways: true }}
        searchRestrictedCountries={['us', 'ca']}
        searchNotificationMessage={searchNotificationMessage}
        customMarkerIcon={customMarkerIcon}
        isCurrentLocation
      />
      <NotificationBox>
        {showLocationNotification && (
          <Notification
            message={translations.LOCATION_PRIVILEGES_ARE_ENABLED}
            title={translations.UNABLE_TO_GET_POSITION}
            onClose={onLocationNotificationClose}
          />
        )}
        {showNotification && (
          <Notification
            message={notificationState?.description || notificationMessage}
            title={notificationState?.title}
            styles={notificationState?.styles}
            onClose={onNotificationClose}
            translations={translations}
          />
        )}
      </NotificationBox>

      {showCurrentLocation && (
        <CurrentLocationBox>
          <CurrentLocationButton
            size="large"
            color="primary"
            variant="contained"
            onClick={() => {
              onCurrentLocation();
              gaTrackEvent(analytics.mapCurrentLocation, {});
            }}
            data-testid={dataTestIds.mapCurrentLocation}
            startIcon={<MyLocationIcon />}
          >
            {translations.CURRENT_LOCATION}
          </CurrentLocationButton>
        </CurrentLocationBox>
      )}
      <Footer>
        <FooterTexts data-testid={dataTestIds.mapFooterInfo}>
          <FooterTitle data-testid={dataTestIds.mapFooterTitle}>
            {footerTitle}
          </FooterTitle>
          {location?.address && (
            <>
              <LocationInfo data-testid={dataTestIds.mapFooterLocationInfo}>
                {location?.address}
              </LocationInfo>
              {locationAdditionalInfo && (
                <LocationInfo
                  data-testid={dataTestIds.mapFooterLocationAdditionalInfo}
                >
                  {locationAdditionalInfo}
                </LocationInfo>
              )}
            </>
          )}
        </FooterTexts>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          disabled={!location?.address}
          onClick={() => {
            sendLocation();
            onConfirm(location);
            gaTrackEvent(analytics.mapConfirmation, location);
          }}
          data-testid={dataTestIds.mapConfirmLocation}
        >
          {translations.CONFIRM_LOCATION}
        </Button>
        <Box
          display={'flex'}
          flexDirection="row"
          gap={1}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
          marginY={3}
        >
          <Typography data-testid={dataTestIds.mapNeedHelp}>
            {translations.NEED_HELP}
          </Typography>
          <Link
            href={`tel:${config?.['contact.phone.number']}`}
            color={'#59CAF5'}
            fontWeight={'bold'}
            underline={'none'}
            data-testid={dataTestIds.mapCallUs}
          >
            {translations.CALL_US}
          </Link>
        </Box>
      </Footer>
    </MapBox>
  ) : (
    <NotFoundPage translations={translations} />
  );
};

export default GoogleMap;
